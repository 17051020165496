import React from 'react'
import {Button, Col, Row, Container} from "react-bootstrap";
import Canvas from 'react';

import Hexagon from './hexagon';



import './home.css'
function Home() {
  return (
    <Container className="Hodler">

            <Row>
                
                <Col xs={4} >
                    <Hexagon/>


                    </Col>
                    <Col xs={4}>


                    </Col>
                    </Row>
                    </Container>
  )
}

export default Home