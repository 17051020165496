import { createRoot } from 'react-dom/client'
import './style.css'



import {Button, Col, Row, Container} from "react-bootstrap";
import Home from './home';

// import  Customizewall from './Customizewall'

createRoot(document.getElementById('root')).render(



< Home/>


)
