import React from 'react'
import ReactDOM from "react-dom";
import * as THREE from "three";
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import NaymerPink from './NeymarPink.jpg';
import NaymerBlack from './Neymarblack.jpg';
import Logo from './logo.png';
import Finisher from './FinisherActive .png';
import Brazil from './BrazilianFlag.png';
import Paris from './paris-saint-germain-logo.png';
import Replaceablelogo from './Top_P.png';
import VideoScene from './VideoScene.png';
import OpenSans from './OpenSans-Bold.json';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { useRef, useEffect } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import HexaFrame from './hexa.glb';
import HexaLightFrame from './HexaLightFrame.glb';
import { useLoader } from '@react-three/fiber'
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
// import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { GlitchPass } from 'three/addons/postprocessing/GlitchPass.js';
import neymarskills from './skillsneymar.mp4';

function Hexagon() {


  const ref = useRef();
  useEffect(() => {





    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 1000);
    // enabled by default
    camera.layers.enable(1);


    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setClearColor(0x000000);
    renderer.autoClear = false;
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    // container.appendChild(renderer.domElement);
    ref.current.appendChild(renderer.domElement);
    scene.background = new THREE.Color(0x00000);



    // Loading Hexagon Frame
    const loader = new GLTFLoader()
    loader.load(HexaFrame, function (glb) {
      console.log(glb)
      const root = glb.scene;
      root.castShadow = true;
      root.receiveShadow = true;
      root.position.set(0,-2.7,0);
      root.scale.set(0.95,0.9,0.95);
      scene.add(root);

    }, function (xhr) {
      console.log((xhr.loaded / xhr.total * 100) + "% loaded")
    }, function (error) {
      console.log('ann error')
    })


        // Loading Hexagon Light Frame
        const LightFrameloader = new GLTFLoader()
        LightFrameloader.load(HexaLightFrame, function (glb) {
          console.log(glb)
          const LightFrame = glb.scene;
          LightFrame.castShadow = true;
          LightFrame.receiveShadow = true;
          LightFrame.position.set(0,0,0);
          // LightFrame.scale.set(0.95,0.9,0.95);
          scene.add(LightFrame);
    
        }, function (xhr) {
          console.log((xhr.loaded / xhr.total * 100) + "% loaded")
        }, function (error) {
          console.log('ann error')
        })
    

    THREE.ColorManagement.legacyMode = false
   renderer.outputEncoding = THREE.sRGBEncoding
    renderer.toneMapping = THREE.ACESFilmicToneMapping
  
    const target = new THREE.WebGLRenderTarget(10, 10, {
      type: THREE.HalfFloatType,
      format: THREE.RGBAFormat,
      encoding: THREE.sRGBEncoding,
    })
    target.samples = 8

    // TEXT

    // var myloader = new FontLoader(); 
    // var font = myloader.parse(OpenSans); 
    // var textGeometry = new TextGeometry('Hello three.js!', 
    // { font: font, size: 1, height: 0.1, curveSegments: 20 });
    //  var textMaterial = new THREE.MeshBasicMaterial({ color: 0xF00000 }); 
    //  var textMesh = new THREE.Mesh(textGeometry, textMaterial);
    //   this.scene.add(textMesh);


    let loader1 = new FontLoader();
    loader1.load(OpenSans, (font) => {
      let xMid;
      let shapes = font.generateShapes("Sample Test", 100, 2);

      let textShape = new THREE.BufferGeometry();
      let geometry = new THREE.ShapeGeometry(shapes);
      geometry.computeBoundingBox();

      let material = new THREE.MeshBasicMaterial({
        color: 0xFFFFFF,
        transparent: true,
        opacity: 1,
        side: THREE.DoubleSide
      });

      xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
      geometry.translate(xMid, 0, 0);

      textShape.fromGeometry(geometry);
      let mesh = new THREE.Mesh(textShape, material);
      mesh.position.set(-6.920, 3.00, 0);
      mesh.rotation.set(0, -90.00 * (Math.PI / 180), 0);
      scene.add(mesh);
      console.log(mesh);
    });

    // const AmbientLight = new THREE.AmbientLight(0x404040);
    // AmbientLight.position.set(0, 0, 0); // soft white Ambient
    // scene.add(AmbientLight);


    const Texture1 = new THREE.TextureLoader().load(NaymerBlack, function (texture) {

      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.offset.set(0, 0);
      texture.repeat.set(1, 1);

    });
   
    // immediately use the texture for material creation

    const material1 = new THREE.MeshStandardMaterial({
      color: 0xFFFFFF,
      side: THREE.DoubleSide,
      castShadow: true,
      emissive: 1,
      map: Texture1
    });


    const Texture2 = new THREE.TextureLoader().load(Replaceablelogo, function (texture) {

      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.offset.set(0, 0);
      texture.repeat.set(1, 1);

    });

    const material2 = new THREE.MeshStandardMaterial({
      color: 0xFFFFFF,
      side: THREE.DoubleSide,
      castShadow: true,
      map:Texture2,
      emissive: 1
    });

    const Texture3 = new THREE.TextureLoader().load(NaymerPink, function (texture) {

      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.offset.set(0, 0);
      texture.repeat.set(1, 1);

    });
    const material3 = new THREE.MeshStandardMaterial({
      color: 0xFFFFFF,
      side: THREE.DoubleSide,
      castShadow: true,
  
     
      map: Texture3
    });


    // const Texture4 = new THREE.TextureLoader().load(VideoScene, function (texture) {

    //   texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    //   texture.offset.set(0, 0);
    //   texture.repeat.set(1, 1);

    // });

    const video = document.getElementById('video');
    video.play()
    const texture5 = new THREE.VideoTexture(video);

    // immediately use the texture for material creation
    const material4 = new THREE.MeshBasicMaterial({
      color: 0xFFFFFF,
      side: THREE.FrontSide,
      castShadow: true,
      toneMapped: false,
      map: texture5,
    });

    const material5 = new THREE.MeshStandardMaterial({
      color: 0xFFFFFF,
      side: THREE.DoubleSide,
      castShadow: true,
      emissive: 1
    });



    const material6 = new THREE.MeshStandardMaterial({
      color: 0xFFFFFF,
      side: THREE.DoubleSide,
      castShadow: true,
      emissive: 1
    });

    const Circlematerial = new THREE.MeshStandardMaterial({
      color: 0xfffcaf,
      side: THREE.DoubleSide,
      castShadow: true,
      emissive: 1
    });

    const ParisLogoTexture = new THREE.TextureLoader().load(Paris, function (texture) {

      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.offset.set(0, 0);
      texture.repeat.set(1, 1);

    });
    const ParislogoMat = new THREE.MeshStandardMaterial({
      color: 0xFFFFFF,
      castShadow: true,
      emissive: 1,
      map: ParisLogoTexture,
    });

    const BrazilLogoTexture = new THREE.TextureLoader().load(Brazil, function (texture) {

      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.offset.set(0, 0);
      texture.repeat.set(1, 1);

    });
    const BrazillogoMat = new THREE.MeshStandardMaterial({
      color: 0xFFFFFF,
      castShadow: true,
      emissive: 1,
      map: BrazilLogoTexture,
    });



    //bloom renderer
    const renderScene = new RenderPass(scene, camera);
    const effectFXAA = new ShaderPass(FXAAShader)
    effectFXAA.uniforms.resolution.value.set(1 / window.innerWidth, 1 / window.innerHeight)


    const bloomPass = new UnrealBloomPass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      1.5,
      0.4,
      0.85
    );
    bloomPass.threshold = 1;
    bloomPass.strength = 1; //intensity of glow
    bloomPass.radius = 1;
    bloomPass.renderToScreen = true;

    const bloomComposer = new EffectComposer(renderer);
    bloomComposer.setSize(window.innerWidth, window.innerHeight);

    const glitchPass = new GlitchPass();
    bloomComposer.addPass(glitchPass);
    bloomComposer.addPass(renderScene);
    bloomComposer.addPass(effectFXAA);
    bloomComposer.addPass(bloomPass);

    renderer.gammaInput = true
    renderer.gammaOutput = true
    renderer.toneMappingExposure = Math.pow(0.9, 4.0)




    // Creating a group for Hexagon
    const SolidHexagon = new THREE.Group();
    SolidHexagon.position.set(0,-3,0);
    scene.add(SolidHexagon);
    //  sidewallgeometry
    const sidewallgeometry = new THREE.PlaneGeometry(8, 6);
    // const material = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );

    // Segment1
    const Segment1 = new THREE.Mesh(sidewallgeometry, material1);
    // Segment1.layers.set(1);
    SolidHexagon.add(Segment1);
    Segment1.position.set(-6.920, 3.00, 0);
    Segment1.rotation.set(0, -90.00 * (Math.PI / 180), 0);



    // Segment2
    const Segment2 = new THREE.Mesh(sidewallgeometry, material2);
    SolidHexagon.add(Segment2);
    Segment2.position.set(-3.476, 3, -6.000);
    Segment2.rotation.set(0, 30 * (Math.PI / 180), 0);



    // Segment3
    const Segment3 = new THREE.Mesh(sidewallgeometry, material3);
    SolidHexagon.add(Segment3);
    Segment3.position.set(3.477, 3, 6.000);
    Segment3.rotation.set(-180.00 * (Math.PI / 180), -30 * (Math.PI / 180), -180.00 * (Math.PI / 180));



    // Segment4
    const Segment4 = new THREE.Mesh(sidewallgeometry, material4);
    SolidHexagon.add(Segment4);
    Segment4.position.set(6.920, 3.00, 0);
    Segment4.rotation.set(0, 90.00 * (Math.PI / 180), 0);



    // Segment5
    const Segment5 = new THREE.Mesh(sidewallgeometry, material5);
    Segment5.layers.set(0);
    SolidHexagon.add(Segment5);
    Segment5.position.set(3.476, 3, -6.000);
    Segment5.rotation.set(0, -30 * (Math.PI / 180), 0);




    // Segment6
    const Segment6 = new THREE.Mesh(sidewallgeometry, material6);
    SolidHexagon.add(Segment6);
    Segment6.position.set(-3.477, 3, 6.000);
    Segment6.rotation.set(-180.00 * (Math.PI / 180), 30 * (Math.PI / 180), -180.00 * (Math.PI / 180));


    // Circle on Brazil wall logo
    const GlowCircle = new THREE.CylinderGeometry(2.50, 2.50, 0.30, 50);
    const BrazilCircle = new THREE.Mesh(GlowCircle, Circlematerial);
    // BrazilCircle.layers.set(1);
    SolidHexagon.add(BrazilCircle);
    BrazilCircle.position.set(3.477, 3, -6.200);
    BrazilCircle.rotation.set(0 * (Math.PI / 180), 60 * (Math.PI / 180), 90 * (Math.PI / 180));

    const LogoCircleGeo = new THREE.CylinderGeometry(2.50, 2.50, 0, 50);
    const LogoCircleBrazil = new THREE.Mesh(LogoCircleGeo, BrazillogoMat);
    SolidHexagon.add(LogoCircleBrazil);
    LogoCircleBrazil.position.set(3.6, 3, -6.400);
    LogoCircleBrazil.rotation.set(0 * (Math.PI / 180), 240 * (Math.PI / 180), 90 * (Math.PI / 180));


    // Circle on Paris wall logo

    const Circle = new THREE.Mesh(GlowCircle, Circlematerial);
    // Circle.layers.set(1);
    SolidHexagon.add(Circle);
    Circle.position.set(-3.477, 3, 6.200);
    Circle.rotation.set(0 * (Math.PI / 180), 60 * (Math.PI / 180), 90 * (Math.PI / 180));




    const LogoCircle = new THREE.Mesh(LogoCircleGeo, ParislogoMat);
    SolidHexagon.add(LogoCircle);
    LogoCircle.position.set(-3.6, 3, 6.450);
    LogoCircle.rotation.set(0 * (Math.PI / 180), 60 * (Math.PI / 180), 90 * (Math.PI / 180));








    //    HexagonGeometry
    const Hexagongeometry = new THREE.CylinderGeometry(8, 8, 0, 6);

    const HexaTopTexture = new THREE.TextureLoader().load(Logo, function (texture) {

      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.offset.set(0, 0);
      texture.repeat.set(1, 1);

    });
    // immediately use the texture for material creation
    const HexaTopMaterial = new THREE.MeshStandardMaterial({ map: HexaTopTexture });

    const HexaBottomTexture = new THREE.TextureLoader().load(Finisher, function (texture) {

      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.offset.set(0, 0);
      texture.repeat.set(1, 1);

    });
    // immediately use the texture for material creation
    const HexaBottomMaterial = new THREE.MeshStandardMaterial({ 
      map: HexaBottomTexture,
     
     });


    //    HexagonTopWall
    const HexaTop = new THREE.Mesh(Hexagongeometry, HexaTopMaterial);
    // HexaTop.layers.enable(1);
    SolidHexagon.add(HexaTop);
    HexaTop.position.set(0, 6, 0);
    HexaTop.rotation.set(0, 0, 0);

    //    HexagonBottomWall
    const HexaBottom = new THREE.Mesh(Hexagongeometry, HexaBottomMaterial);
    // HexaBottom.layers.enable(1);
    SolidHexagon.add(HexaBottom);
    HexaBottom.position.set(0, 0, 0);
    HexaBottom.rotation.set(0, 0, 0);







    // const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    // directionalLight.position.set(12, 12, 0); //default; directionalLight shining from top
    // directionalLight.castShadow = true;
    // directionalLight.receiveShadow = false; //default
    // scene.add(directionalLight);

    // const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.5);
    // directionalLight2.position.set(-12, 12, 0); //default; directionalLight shining from top
    // directionalLight2.castShadow = true;
    // directionalLight2.receiveShadow = false; //default
    // scene.add(directionalLight2);

    // // Create a PointLight and turn on shadows for the light
    // const light = new THREE.PointLight(0xffffff, 1.00, 100);
    // light.position.set(21.676, 15.554, -6.130);
    // light.castShadow = true; // default false
    // scene.add(light);


    const width = 10;
    const height = 10;
    const intensity = 1;
    const rectLight = new THREE.RectAreaLight(0xffffff, intensity, width, height);
    rectLight.position.set(0, 10, 15);
    rectLight.lookAt(0, 0, 0);
    scene.add(rectLight)


    const rectLight2 = new THREE.RectAreaLight(0xffffff, intensity, width, height);
    rectLight2.position.set(0, 10, -15);
    rectLight2.lookAt(0, 0, 0);
    scene.add(rectLight2)

    const rectLight3 = new THREE.RectAreaLight(0xffffff, intensity, width, height);
    rectLight3.position.set(0, -10, -15);
    rectLight3.lookAt(0, 0, 0);
    scene.add(rectLight3)


    const rectLight4 = new THREE.RectAreaLight(0xffffff, intensity, width, height);
    rectLight4.position.set(0, -10, 15);
    rectLight4.lookAt(0, 0, 0);
    scene.add(rectLight4)

    const rectLight5 = new THREE.RectAreaLight(0xffffff, intensity, width, height);
    rectLight5.position.set(10, 5, 0);
    rectLight5.lookAt(0, 0, 0);
    scene.add(rectLight5)

    const rectLight6 = new THREE.RectAreaLight(0xffffff, intensity, width, height);
    rectLight6.position.set(-10,5, 0);
    rectLight6.lookAt(0, 0, 0);
    scene.add(rectLight6)

    const rectLight7 = new THREE.RectAreaLight(0xffffff, intensity, width, height);
    rectLight7.position.set(10,-5, 0);
    rectLight7.lookAt(0, 0, 0);
    scene.add(rectLight7)

    const rectLight8 = new THREE.RectAreaLight(0xffffff, intensity, width, height);
    rectLight8.position.set(-10,-5, 0);
    rectLight8.lookAt(0, 0, 0);
    scene.add(rectLight8)

    // const rectLightHelper = new RectAreaLightHelper(rectLight);
    // rectLight.add(rectLightHelper);

    // const rectLightHelper2 = new RectAreaLightHelper(rectLight5);
    // rectLight5.add(rectLightHelper2);


    const controls = new OrbitControls(camera, renderer.domElement);
    // to disable zoom
    controls.enableZoom = false;

    // to disable pan
    controls.enablePan = false;

    camera.position.x = 0;
    camera.position.y = 0;
    camera.position.z = 20;

    camera.rotation.x = 0 * (Math.PI / 180);
    camera.rotation.y = 0 * (Math.PI / 180);
    camera.rotation.z = 0 * (Math.PI / 180);
    controls.update();



    // const size = 40;
    // const divisions = 10;
    
    // const gridHelper = new THREE.GridHelper( size, divisions );
    // scene.add( gridHelper );




  // gsap

  // gsap.registerPlugin(ScrollTrigger);

  // gsap.to(mesh.rotation, {
  //   scrollTrigger: {
  //   trigger: "#trigger",
  //   start: "top top",
  //   end: "bottom top",
  //   scrub: true,
  //   toggleActions: "restart pause resume pause"
  // },
  //   y: Math.PI
  // });



    





    //resize listner
    // window.addEventListener(
    //   "resize",
    //   () => {
    //     camera.aspect = window.innerWidth / window.innerHeight;
    //     camera.updateProjectionMatrix();
    //     renderer.setSize(window.innerWidth, window.innerHeight);
    //     bloomComposer.setSize(window.innerWidth, window.innerHeight);
    //   },
    //   false
    // );



    const animate = () => {
      requestAnimationFrame(animate);
      texture5.needsUpdate = true;
    
      // SolidHexagon.rotation.y += 0.001;
      renderer.clear();

      camera.layers.set(1);
      bloomComposer.render();

      renderer.clearDepth();



      camera.layers.set(0);
      renderer.render(scene, camera);

    };


    animate();



  });
  return (
    <>
      <video id="video" src={neymarskills} loop crossOrigin="anonymous" playsInline muted style={{ display: "none" }}>

      </video>

      <div ref={ref} />
    </>
  )
}

export default Hexagon